/*
Reset default styles
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}
